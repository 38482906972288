<template>
  <v-row>
    <v-col cols="12" sm="12" md="12">
      <Chains></Chains>
      <Channels></Channels>
    </v-col>
  </v-row>
</template>

<script>
import Chains from "./Chains.vue";
import Channels from "./Channels.vue";

export default {
  name: "chains",
  components: {
    Chains,
    Channels,
  },
};
</script>

<style></style>
